.shared-panel{
	overflow: auto;
    display: flex;
    justify-content: center;
}
.shared {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin: 10px 0;
	padding: 7.5px;
	margin-right: 5px;
	cursor: pointer;
	border-radius: 50%;
	
}
.shared:hover{
    width: 60px;
}

.imgredes{
	width: 100%;
}
