.registro {
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: auto;
}
.registro h1 {
  text-align: center;
  color: rgb(255, 249, 240);
}
.registro .row {
  margin: 15px 0;
}
.registro .btn {
  width: 100%;
  background: #322924;
  border: none;
  color: rgb(255, 249, 240);
}
.registro .volver {
  background: #504d4e;
  color: rgb(255, 249, 240);
  padding: 10px 10px 12px 10px;
  box-sizing: border-box;
  border-radius: 10px;
}
