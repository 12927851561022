.inicio {
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
h2 {
  text-align: center;
  color: #cecfcf;
}

navbar {
  width: 100%;
}
navbar img {
  width: 80px;
}
.inicio ul {
  color: #d3d4d6;
  text-decoration: none;
  display: flex;
  list-style: none;
}
.inicio ul li {
  padding: 5px 10px;
  box-sizing: border-box;
}
.inicio ul li:hover {
  color: rgb(138, 138, 138);
  padding: 10px 20px;
  box-sizing: border-box;
}
/* INICIO ESTILOS MI PERFIL */
.inicio .row {
  padding: 0;
  margin: 0;
}
.inicio .resumen .chard {
  background: #fff;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
}
.inicio .resumen .chard canvas {
  width: 100% !important;
}
.inicio-menu a {
  color: aliceblue;
}
.inicio-section {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.inicio-section row {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.inicio .cont {
  width: 100%;
}
.inicio .cont .subcont0 {
  width: 50%;
  float: left;
  padding: 15px;
  box-sizing: border-box;
}

.inicio .cont .subcont {
  background: rgba(255, 57, 133, 0.3);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  color: #cecfcf;
  border-radius: 10px;
}
.inicio .box {
  width: 100%;
  display: flex;
}
.inicio .subbox {
  width: 33%;
}
.subcont h2 {
  color: antiquewhite;
}
.inicio .rango {
  font-size: 20px;
}
.data-user {
  display: flex;
}
.tarjeta {
  background: #3671ac;
  color: #000;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;

  background-image: url(https://coulant.online/img/tarjeta-clasica.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.permium {
  background-image: url(https://coulant.online/img/tarjeta-premium.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: #822525;
}
.permium .boton-info2 {
  color: #822525;
}
.permium .boton-info2:hover {
  color: #d7d7d7;
}

.deluxe {
  background-image: url(https://coulant.online/img/tarjeta-deluxe.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: #f8f3d5;
}
.deluxe .boton-info2 {
  color: #f8f3d5;
}
.deluxe .boton-info2:hover {
  color: #a0cd0e;
}

.tarjeta .title-socio {
  text-align: left;
  font-weight: bold;
}
.data-user-box {
  padding-right: 60px;
  font-weight: bold;
  text-transform: uppercase;
}
.boton-info2 {
  color: #5252e8;
}

.index-section h4 {
  text-align: center;
  color: aliceblue;
}
.index-section .buscador {
  display: flex;
  text-align: center;
  width: 100%;
  max-width: 270px;
  margin: auto;
}
.index-section .boxes {
  display: flex;
  width: 100%;
  max-width: 540px;
  margin: 20px auto;
}
.index-section .boxes .subboxes {
  background: #3671ac;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  margin: 0 20px;
}
.index-section .boxes .subboxes a {
  color: aliceblue;
}
.cl-boxes {
  margin-top: 20px;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}
.clientes-detalle {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 20px 0;
}
.ni-boxes {
  display: flex;
  margin-bottom: 20px;
}
.ni-boxes .ni-subboxes {
  width: 50%;
  padding: 5px;
  box-sizing: border-box;
}
.ni-boxes .ni-subboxes .form {
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
}
.ni-boxes .ni-subboxes .form .form-control {
  margin: 10px 0;
}
.ni-titulo {
  margin: 20px 0;
}
.cont .row {
  margin: 0;
}
.cont .row .col {
  margin: 10px 0;
  max-width: 33.3%;
}
.cont .row .col img {
  border-radius: 10px;
  box-shadow: 5px 5px 5px #000;
}
.cont .row .col .niveles-cont {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
}
.cont .row .col .niveles-cont td {
  color: #000000;
}
/* FIN ESTILOS MI PERFIL */
.datos-edit .subcont {
  padding: 20px 0 !important;
}
.datos-edit .subcont .col {
  min-width: 400px;
}
/*estilos cliente*/
.ni-boxes .row {
  width: 100%;
  padding: 0;
  margin: 0;
}
.ni-boxes .row .col {
  padding: 10px;
  min-width: 400px;
}
.ni-boxes .row .form {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 0;
}
.ni-boxes .row .form .btn {
  margin-top: 10px;
  width: 100%;
}
.canvas {
  width: 100%;
  height: 80vh;
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px #000;
  background: rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width: 900px) {
  .tarjeta {
    background-size: cover;
  }
  .inicio-section .cont .subcont0 {
    width: 100%;
  }
  .cont .row .col {
    margin: 10px 0;
    max-width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  navbar img {
    width: 50px;
  }
  navbar ul li {
    line-height: 50px;
    padding: 2px 5px;
    box-sizing: border-box;
  }
  .inicio .cont .subcont0 {
    width: 100%;
  }
  .ni-boxes {
    display: block;
  }
  .ni-boxes .ni-subboxes {
    width: 100%;
  }
  .index-section .boxes .subboxes {
    margin: 0 5px;
  }
  .cont .row .col {
    margin: 10px 0;
    max-width: 100%;
  }
  .ni-boxes .row .col {
    padding: 10px 0px;
  }
}
