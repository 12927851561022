.shared-wa{
	overflow: auto;
    display: flex;
    justify-content: center;
}
.wa {
	width: 75px;
	height: 75px;
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	border-radius: 50%;
	z-index: 10000;
}
.wa:hover{
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}

.imgwa{
	width: 100%;
}
