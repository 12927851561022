body {
  margin: 0;
  background: rgb(249, 147, 186);
  background: linear-gradient(
    180deg,
    rgba(50, 41, 36, 1) 0%,
    rgb(112, 98, 91) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
.login {
  background-image: url(https://coulant.online/img/couland-backround.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 30px;
  box-sizing: border-box;
}
.img-login {
  width: 100%;
  max-width: 200px;
  margin: 20px auto;
  text-align: center;
  color: rgb(255, 249, 240);
}
.img-login img {
  width: 100%;
}
.login .form-control {
  background: none;
  border: none;
  border-bottom: 3px solid rgb(255, 239, 228);
  margin: 20px 0;
  text-align: center;
}
.login .login-boton {
  text-align: right;
  color: rgb(255, 249, 240);
}
.login .btn {
  width: 100%;
  background: #322924;
  border: none;
}
.login .login-register {
  color: rgb(255, 249, 240);
  text-align: center;
}

.login .form-control::-webkit-input-placeholder {
  text-align: center;
  line-height: 100px;
  color: #f7e3f8;
}

.login .form-control:focus {
  color: #f7e3f8;
}

.login .input-wrapper {
  width: 100%;
  position: relative;
}

.login .input-icon {
  color: #f7e3f8;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.login a {
  color: #f7e3f8;
}
.inp-pass {
  display: flex;
}
.show-pass {
  position: absolute;
  color: #322924;
  width: 25px;
  height: 25px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
